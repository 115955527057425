import React , { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '@assets/img/hd-logo.png'

class Logo extends Component{
  render(){
    return (
      <div className="logo">
        <Link to="/">
        <img src={logo} alt="墨子实验室" />
        </Link>
      </div>
    )
  }
}


export default Logo