import React , { Component , Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import ProductPage from '@components/Product'
import '@styles/product.scss'

class Product extends Component{
  render(){
    return (
      <Fragment>
        <ProductPage />
      </Fragment>
    )
  }
}

export default withRouter(Product)