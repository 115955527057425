import React , { Component } from 'react'
import weibo from '@img/weibo.png'
import weixin from '@img/weixin.png'
import wxcode from '@img/wechat.jpg'
import wbcode from '@img/weibo.jpg'

class Sider extends Component{
  constructor(props){
    super(props)
    this.state = {
      activeIndex:0,
      show:false
    }
  }
  render(){
    let { state } = this 
    return (
      <div className="page-sider">
        <div className="sider-content">
          <div className="sider-item" onMouseOver={this.mousein.bind(this,0)} onMouseLeave={this.mouseout.bind(this,0)}>
            <img src={weixin} alt="微信" />
          </div>
          <div className="sider-line"></div>
          <div className="sider-item" onMouseOver={this.mousein.bind(this,1)} onMouseLeave={this.mouseout.bind(this,1)}>
           <img src={weibo} alt="微博" />
          </div>
        </div>
        
        <div 
          className={`sider-wrapper animated ${state.show?'fadeInLeft':'fadeOutLeft'}`} 
          style={state.show?{display:'block'}:{display:'none'}}
        >
          <div className="sider-wrapper-arrow" style={{top:`${20+50*state.activeIndex}px`}}></div>
          {state.activeIndex===0?(<img src={wxcode} alt="微信" />):(<img src={wbcode} alt="微博" />)}
        </div>
      </div>
    )
  }
  mousein = (index)=>{
    this.setState({
      activeIndex:index,
      show:true
    })
  }
  mouseout = ()=>{
    let {state} = this
    state.show = false
    this.setState(state)
  }
}


export default Sider