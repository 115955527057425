import Index from '@views/index'
import Product from '@views/product'
import Service from '@views/service'
import Dynamic from '@views/dynamic'
import News from '@views/news'

import About from '@views/about'

const routers = [
  {
    path:'/',
    title:'首页',
    nav:true,
    component:Index
  },
  {
    path:'/product',
    title:'产品',
    nav:true,
    component:Product
  },
  {
    path:'/service',
    title:'服务',
    nav:true,
    component:Service
  },
  {
    path:'/dynamic',
    title:'动态',
    nav:true,
    component:Dynamic
  },
  {
    path:'/about',
    title:'关于',
    nav:true,
    component:About
  },
  {
    path:'/dynamic/:id',
    title:'动态',
    nav:false,
    component:News
  }
]

export default routers