import React , { Component } from 'react'
import Ticker from 'react-ticker'

class IndexBussiness extends Component{
  constructor(props){
    super(props)
    this.state = {
      move:true
    }
  }
  render(){
    let { move } = this.state
    return (
      <section id="index-bussiness">
        <div className="bussiness-line"></div>
        <div className="container">
          <div className="index-title">
            <p className="index-title-main">
              <span className="index-title-line"></span>
              主要业务方向
            </p>
            <p className="index-title-sub">MAIN BUSINESS DIRECTION</p>
          </div>
          <div className="outer-box">
            <Ticker speed={10} move={move}>
              {({ index }) => (
                <div className="bussiness-box" onMouseOver={this.stopTicker} onMouseLeave={this.startTicker}>
                  <div className="bussiness-box-item" >
                      <p>应急响应</p>
                      <div className="item-line">
                        <div className="item-line-fill"></div>
                        <div className="item-line-icon">
                          <span></span>
                          <span className="lines"></span>
                          <span className="circle"></span>
                          <span className="lines"></span>
                          <span></span>
                        </div>
                        <div className="item-line-fill"></div>
                      </div>
                      <p>安全事件应急响应 和事后补救</p>
                    </div>
                  <div className="bussiness-box-item" >
                    <p>安全评估</p>
                    <div className="item-line">
                      <div className="item-line-fill"></div>
                      <div className="item-line-icon">
                        <span></span>
                        <span className="lines"></span>
                        <span className="circle"></span>
                        <span className="lines"></span>
                        <span></span>
                      </div>
                      <div className="item-line-fill"></div>
                    </div>
                    <p>区块链安全咨询 和技术顾问</p>
                  </div>
                  <div className="bussiness-box-item" >
                    <p>安全保障</p>
                    <div className="item-line">
                      <div className="item-line-fill"></div>
                      <div className="item-line-icon">
                        <span></span>
                        <span className="lines"></span>
                        <span className="circle"></span>
                        <span className="lines"></span>
                        <span></span>
                      </div>
                      <div className="item-line-fill"></div>
                    </div>
                    <p>为您的系统提供强有力的安全技术支持</p>
                  </div>
                  <div className="bussiness-box-item" >
                    <p>安全审计</p>
                    <div className="item-line">
                      <div className="item-line-fill"></div>
                      <div className="item-line-icon">
                        <span></span>
                        <span className="lines"></span>
                        <span className="circle"></span>
                        <span className="lines"></span>
                        <span></span>
                      </div>
                      <div className="item-line-fill"></div>
                    </div>
                    <p>底层,合约,钱包,交易所的安全审计和风险测评</p>
                  </div>
                  <div className="bussiness-box-item" >
                    <p>安全咨询</p>
                    <div className="item-line">
                      <div className="item-line-fill"></div>
                      <div className="item-line-icon">
                        <span></span>
                        <span className="lines"></span>
                        <span className="circle"></span>
                        <span className="lines"></span>
                        <span></span>
                      </div>
                      <div className="item-line-fill"></div>
                    </div>
                    <p>区块链安全咨询 和技术顾问</p>
                  </div>
                </div>
              )}
            </Ticker>
            <span className="moving-cursor"></span>
          </div> 
        </div>
      </section>
    )
  }
  stopTicker = ()=>{
    this.setState({
      move:false
    })
  }
  startTicker = ()=>{
    this.setState({
      move:true
    })
  }
}

export default IndexBussiness