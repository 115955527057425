import React from 'react';
import Layout from '@components/Layout'
import { BrowserRouter as Router } from 'react-router-dom'
import '@utils/mock'
import '@styles/reset.scss'
import '@styles/media.scss'

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
