import React , { Component } from 'react'
import { Switch , withRouter } from 'react-router-dom'
import IndexBanner from '@components/Banner'
import routers from '@router/config'
import ContentRouter  from '@router'
import productbg from '@img/imgbg-product.png'
import newsbg from '@img/imgbg-news.png'
import aboutbg from '@img/imgbg-about.png'
import servicebg from '@img/imgbg-service.png'

class Content extends Component{
  render(){
    const { props } = this
    let pathname = props.location.pathname
    var imgbg = newsbg
    if(pathname==='/product'){
      imgbg = productbg
    }else if(pathname==='/service'){
      imgbg = servicebg
    }else if(pathname==='/about'){
      imgbg = aboutbg
    }else {
      imgbg = newsbg
    }
    return (
      <div id="content">
        {
          pathname === '/'? <IndexBanner />:(
            <div className='single-banner'>
              <img src={imgbg} alt="xxx" />
            </div>
          )
        }
        <Switch>
        {
          routers.map((item,index)=>{
            return (
              <ContentRouter key={index} {...item} exact />
            )
          })
        }
        </Switch>
      </div>
    )
  }
  UNSAFE_componentWillReceiveProps(props){
    // console.log(props)
  }
}

export default withRouter(Content)