import React , { Component , Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import ServicePage from '@components/Service'
import ServiceItem1 from '@img/service-item1.png'
import ServiceItem2 from '@img/service-item2.png'
import ServiceItem3 from '@img/service-item3.png'
import '@styles/service.scss'

class Service extends Component{
  constructor(props){
    super(props)
    this.state = {
      service:[
        {
          title:'安全评估',
          sub:'SAFETY ASSESSMENT',
          img:ServiceItem1,
          content:'区块链平台安全测评，DAPP安全测评，交易所安全测评，数字钱包安全测评，矿池运行态势安全测评，办公环境安全评估'
        },
        {
          title:'安全响应',
          sub:'SECURITY RESPONSE',
          img:ServiceItem2,
          content:'钱包安全事件应急响应，交易所安全事件应急响应，区块链运行态势监测及应急响应，DAPP安全态势监测及应急响应'
        },
        {
          title:'安全保障',
          sub:'SAFETY GUARANTEE',
          img:ServiceItem3,
          content:'真随机数服务，安全威胁情报服务，安全开发培训，运营人员安全意识培训'
        }
      ],
      activeIndex:0  
    }
  }
  render(){
    let { state } = this
    return (
      <Fragment>
        <ServicePage 
          service = { state.service } 
          activeIndex = { state.activeIndex } 
          setActiveIndex = { this.setActiveIndex }
        />
      </Fragment>
    )
  }
  setActiveIndex = (index)=>{
    console.log(index)
    this.setState({
      activeIndex:index
    })
  }
}

export default withRouter(Service)