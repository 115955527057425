import axios from './index'

const getNewsList = (param)=>{
  return axios({
    url:'https://apioperate.btc123.com/api/content/findArticleByCategoryId',
    method:'get',
    params:param
  })
}





export { getNewsList }