import React , { Component } from 'react'
import { Link , withRouter } from 'react-router-dom'
import Banner1 from '@img/banner1.png'
import Banner2 from '@img/banner2.jpg'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'


class IndexBanner extends Component{
  constructor(props){
    super(props)
    this.state = {
      bannerList:[
        Banner1,
        Banner2
      ]
    }
  }
  render(){
    // let { state } = this
    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    }
    return (
      <section id="banner">
        <Swiper {...params}>
          <div className="banner-item">
            <div className="banner-img" style={{backgroundImage:`url(${Banner1})`}}></div>
            <div className="banner-desc animated slideInUp">
              <h2>专注区块链生态安全</h2>
              <h5>FOCUS ON THE ECOLOGICAL SECURITY OF BLOCKCHAIN</h5>
              <p className="banner-btn">
                <Link to="/about" title="ABOUT US">ABOUT US</Link>
              </p>
            </div>
          </div>
          <div className="banner-item">
            <div className="banner-img" style={{backgroundImage:`url(${Banner2})`}}></div>
          </div>
        </Swiper>
      </section>
    )
  }
}

export default withRouter(IndexBanner)