import React , { Component } from 'react'
import { Link } from 'react-router-dom'
import service1 from '@img/service1.png'
import service2 from '@img/service2.jpeg'
import service3 from '@img/service3.png'

class IndexService extends Component{
  render(){
    return (
      <section id="index-service">
        <div className="container">
          <div className="service-title">
            <div className="index-title">
              <p className="index-title-main">
                <span className="index-title-line"></span>
                附加服务
              </p>
              <p className="index-title-sub">ADDITIONAL SERVICES</p>
            </div>
            <div className="service-more">
              <Link to="/service" title="更多服务">更多 》</Link>
            </div>
          </div>
          <div className="service-box">
            <div className="service-box-wrapper">
              <div className="service-item">
                <div className="service-item-content animated fast">
                  <p className="title">真随机数服务平台</p>
                  <p>随机数是信息安全的基石，同时也是区块链安全的薄弱环节。</p>
                  <p>我们基于量子随机数发生器提供安全可信的真随机数服务，为区块链安全提供安全基础设施。</p>
                  <Link to="/service" title="真随机数服务平台"><span>点击查看 》</span></Link>
                </div>
                <img src={service1}  alt="真随机数服务平台"/>
                <p className="item-title">真随机数服务平台</p>
                <p>随机数是信息安全的基石，同时也是区块链安全的薄弱环节。我们基于...</p>
              </div>
              <div className="service-item">
                <div className="service-item-content animated fast">
                  <p className="title">用户隐私保护系统</p>
                  <p>公开透明是区块链技术的优点之一，但是也有利于不法之徒对用户资产的非法窥探和跟踪。</p>
                  <p>我们为用户提供隐私保护服务，有效抵制对用户的非法窥探。</p>
                  <Link to="/service" title="用户隐私保护系统"><span>点击查看 》</span></Link>
                </div>
                <img src={service2}  alt="用户隐私保护系统"/>
                <p className="item-title">用户隐私保护系统</p>
                <p>公开透明是区块链技术的优点之一，但是也有利于不法之徒对用户资产...</p>
              </div>
              <div className="service-item">
                <div className="service-item-content animated fast">
                  <p className="title">区块链安全培训</p>
                  <p>区块链时代，信息安全知识成为人们的标配。为了有效保障个人数字资产的安全，必须具备必要的安全意识。</p>
                  <p>为了开发实用的区块链应用，必须具备基本的信息安全知识。</p>
                  <Link to="/service" title="区块链安全培训"><span>点击查看 》</span></Link>
                </div>
                <img src={service3}  alt="区块链安全培训"/>
                <p className="item-title">区块链安全培训</p>
                <p>区块链时代，信息安全知识成为人们的标配。为了有效保障个人数字资产的安全...</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default IndexService