import React , { Fragment , useState } from 'react'
import Swiper from 'react-id-swiper'
import avatar1 from '@img/avatar1.png'
import avatar2 from '@img/avatar2.png'
import avatar3 from '@img/avatar3.png'
import avatar4 from '@img/avatar4.png'

const Team = ()=>{
  // constructor
  const [swiper, updateSwiper] = useState(null);
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const params = {
    slidesPerView: 3,
    spaceBetween: 21,
    loop:true
  }
  return (
    <Fragment>
      <section>
        <p className="content-title">
          团队介绍 / TEAM INTRODUCTION
        </p>
        <p>墨子安全实验室核心人员来自我国信息安全领域的国家队：  信息安全国家重点实验室。</p>
        <p>丰富的区块链安全经验。核心成员来自中国科学院、华为、中兴、IBM中国研究院等知名机构，具备多年的信息安全工作经验，担任多个区块链公司的安全顾问。</p>
        <p>工作态度严谨而不失活力。我们的平均年龄仅有31岁，研发经验丰富，同时充满了朝气和创新精神。</p>
        <p>我们是一支专注的团队。墨子安全实验室心无旁骛，聚焦区块链安全，我们坚信，只有专注，才能做好安全。</p>
        <p>立足一线，务实进取。我们坚决反对实验室里的空想，坚持立足一线，听取来自一线的真实声音，深刻体会一线的痛点，发掘用户真实需求，切实解决用户面临的实际问题。</p>
      </section>
      <section>
        <p className="content-title">
          主要成员 / LEADING MEMBER
        </p>
        <div className="nember-swiper">
          <div className="swiper-item-next" onClick={goPrev}></div>
          <div className="swiper-item-prev" onClick={goNext}></div>
          <Swiper {...params} getSwiper={updateSwiper}>
            <div className="member-item">
              <div className="member-avatar">
                <img src={avatar1} alt="主要成员" />
              </div>
              <p className="member-name">苗知秋 博士</p>
              <p>毕业于中国科学院信息安全国家重点实验室，现为山东大学计算机学院博士后。 </p>
              <p>在P2P网络安全、分布式系统安全、物联网安全、无线网络安全、分布式信任管理等方面具有较深厚的研究积累。</p>
            </div>
            <div className="member-item">
              <div className="member-avatar">
                <img src={avatar2} alt="主要成员" />
              </div>
              <p className="member-name">闫之楠</p>
              <p>曾经在IBM中国研究院、谷歌和凤凰网等公司工作，拥有十多年互联网开发和项目管理经验，擅长软件系统设计与实施，具有丰富的项目管理经验，是区块链技术的早期参与者。</p>
             </div>
            <div className="member-item">
              <div className="member-avatar">
                  <img src={avatar3} alt="主要成员" />
                </div>
              <p className="member-name">周苏静 博士</p>
              <p>毕业于中国科学院信息安全国家重点实验室。</p>
              <p>曾长期任中兴通讯股份有限公司资深安全技术专家，标准预研专家，云安全联盟(CSA)标准专家。</p>
              <p>主要研究方向为密码学和安全协议，在群组签名和群组身份认证领域具有深厚的研究积累。</p>
            </div>
            <div className="member-item">
              <div className="member-avatar">
                  <img src={avatar4} alt="主要成员" />
                </div>
              <p className="member-name">张宏 博士</p>
              <p>信息安全专业博士，毕业于中科院软件所</p>
              <p>曾长期担任中船集团信息安全专家，华为技术有限公司信息安全架构师，并曾担任多家支付机构的安全顾问。</p>
              <p>15年以上IT行业从业经验，10年以上信息安全领域资深经验。主要研究方向为渗透测试和漏洞挖掘，恶意代码分析及逆向工程等。</p>
            </div>
          </Swiper>
        </div>
      </section>
    </Fragment>
  )
}

export default Team