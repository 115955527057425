import React , { Component } from 'react'
import { Link } from 'react-router-dom'
import Ozonosphere from '@img/Ozonosphere.png'

class IndexProduct extends Component{
  constructor(props){
    super(props)
    this.state = {
      data:[
        {
          title:'智能合约安全审计平台',
          sub:'OZONOSPHERE',
          list:[
            '多引擎：静态、动态、模糊',
            '跨平台：ETH、EOS、MOAC',
            '跨语言：SOLIDITY、VYPER、C++',
            '人工智能辅助',
            '报告自动输出'
          ]
        },
        {
          title:'区块链安全态势感知平台',
          sub:'STRATOSPHERE',
          list:[
            '区块链底层安全态势感知',
            'Dapp运营态势感知与分析',
            '底层分叉风险评估',
            '异常行为建模画像',
            '数据可视化服务'
          ]
        },
        {
          title:'数字资产综合保护平台',
          sub:'TROPOSPHERE',
          list:[
            '私钥安全托管平台',
            '私钥部分缺失找回系统',
            'Keystore文件破解系统',
            '数字资产流转可视化与跟踪系统',
            '基于安全硬件的重资产钱包'
          ]
        },
        {
          title:'区块链安全攻防演练平台',
          sub:'IONOSPHERE',
          list:[
            '交互式、可配置攻防演练',
            '基本的安全对抗环境',
            '常见的安全攻击手法',
            '便于直观认识区块链安全事件',
            '可作为高校、机构安全培训工具'
          ]
        }
      ],
      activeIndex:0
    }
  }
  render(){
    let { state } = this
    let activeItem = state.data[state.activeIndex]
    return (
      <section id="index-product">
        <div className="container">
          <div className="index-product_left">
            <div className="top-triangle"></div>
            <div className="index-title">
              <p className="index-title-main">
                <span className="index-title-line"></span>
                产品展示
              </p>
              <p className="index-title-sub">PRODUCT DISPLAY</p>
            </div>
            <div className="product-desc">
              {
                state.data.map((item,index)=>{
                  return (
                    <p 
                    key={ index }
                    className={index===state.activeIndex?'desc-title-active':null}
                    onClick={ this.setIndex.bind(this,index) } 
                    >
                      {item.title}
                    </p>
                  )
                })
              }
              <span 
                className="active-line"
                style={{
                  top:`${40*state.activeIndex+13}px`
                }}
              ></span>
            </div>
          </div>
          <div className="index-product_right">
            <div className="product-img">
              <img src={Ozonosphere} alt="智能合约安全审计平台" /> 
            </div>
            <div className="product-box">
              <div className="box-title">
                <p>{activeItem.title}</p>
                <p>{activeItem.sub}</p>
              </div>
              <ul>
                {activeItem.list.map((item,index)=>{
                  return (<li key={index}>  
                    {item}
                  </li>)
                })}
              </ul>

              <div className="product-more">
                <Link to="/product" title="更多产品">
                  MORE
                  <span></span>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </section>
    )
  }
  setIndex = (index)=>{
    this.setState({
      activeIndex:index
    })
  }
}

export default IndexProduct