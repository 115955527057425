import React , { Component , Fragment } from 'react'
import { Header , Content , Footer } from './Layouts'
import '@styles/layout/layout.scss'

// assets/
class MainLayout extends Component{
  render(){
    return (
      <Fragment>
        <Header />
        <Content />
        <Footer />
      </Fragment>
    )
  }
}


export default MainLayout