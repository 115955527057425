import axios from 'axios'

axios.defaults.baseURL = 'http://localhost:3000/api/'

axios.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return err
  }
)

axios.interceptors.response.use(

  response => {
    console.log(response.data)
    if (response.status === 200) {
      return response.data
    }
  },
  err => {
    console.log(err)

    return err
  }
)

export default axios