import React , { Component , Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import DynamicPage from '@components/Dynamic'
import '@styles/dynamic.scss'

class Dynamics extends Component{
  render(){
    return (
      <Fragment>
        <DynamicPage /> 
      </Fragment>
    )
  }
}

export default withRouter(Dynamics)