import Mock from 'mockjs'
import { builder } from '../builder'

const newsDetail = ()=>{
  const detail = {
    
  }
  return builder(detail)
}

Mock.mock(/\/api\/newsDetail/,'post',newsDetail)