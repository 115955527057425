import Mock from 'mockjs'
import { builder } from '../builder'

const newsList = ()=>{
  const list = {
    "data": {
        "startRow": 11,
        "navigatepageNums": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8
        ],
        "prePage": 1,
        "hasNextPage": true,
        "nextPage": 3,
        "pageSize": 10,
        "endRow": 20,
        "list": [
            {
                "summary": "四种收益中，检索矿工只有一种收益：那就是检索收益，而其它三种收益都只有存储矿工才有资格得到。",
                "isRecommend": 0,
                "createTimeLong": 1576288840000,
                "sourceLink": null,
                "creatorName": "17748488352",
                "source": "道说区块链",
                "title": "解读Filecoin矿工收益模式",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/d1c6153e6c0145c080ee4ed84e9bae49",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-14 10:00:40",
                "firstBrowse": 0,
                "id": 39725,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 9421,
                "lang": "zh-cn",
                "createTimeStr": "12-14"
            },
            {
                "summary": "据经济学家兼全球市场分析师亚历克斯•克瑞格（alex kr_ger）称，高效比特币采矿业务的盈亏平衡成本目前徘徊在3550美元左右。",
                "isRecommend": 0,
                "createTimeLong": 1576156913000,
                "sourceLink": null,
                "creatorName": "SEO",
                "source": "火星财经",
                "title": "比特币矿业终于盈利，这证明我们处于牛市吗？",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/a571402ef7d24636866a833c1ba877be",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-12 21:21:53",
                "firstBrowse": 0,
                "id": 39648,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 16998,
                "lang": "zh-cn",
                "createTimeStr": "12-12"
            },
            {
                "summary": "“这个行业很残酷，哪怕你前面都是领先，只要有一代落后你就落后了。”",
                "isRecommend": 0,
                "createTimeLong": 1576156806000,
                "sourceLink": null,
                "creatorName": "SEO",
                "source": "财经杂志",
                "title": "比特币矿业：5nm芯片战打响第一枪，进军家庭推出“挖矿电视”",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/3dad3fc8376b4d759353135225cac8a9",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-12 21:20:06",
                "firstBrowse": 0,
                "id": 39647,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 16972,
                "lang": "zh-cn",
                "createTimeStr": "12-12"
            },
            {
                "summary": "“四川应利用比特币挖矿的基础，研究区块链、数字货币、水电资源等在内的关系，从中发掘新的产业增长点”中国证监会原副主席姜洋此前建议。",
                "isRecommend": 0,
                "createTimeLong": 1576156640000,
                "sourceLink": null,
                "creatorName": "SEO",
                "source": "火星财经",
                "title": "中国拿下2/3的比特币算力份额，54%的算力在四川！",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/9a3784c4f2ad4516ba152bf21bd40afe",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-12 21:17:20",
                "firstBrowse": 0,
                "id": 39646,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 16996,
                "lang": "zh-cn",
                "createTimeStr": "12-12"
            },
            {
                "summary": "“随时可能反击的詹克团、股东的期许、对手的挑衅以及行业的变化，比特大陆内忧外患。”",
                "isRecommend": 0,
                "createTimeLong": 1576156198000,
                "sourceLink": null,
                "creatorName": "SEO",
                "source": "火星财经",
                "title": "四十六天，熟悉的吴忌寒和比特大陆回来了？",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/6f5aa73fe2cd4d1ebade2ed5820fd608",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-12 21:09:58",
                "firstBrowse": 0,
                "id": 39645,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 9199,
                "lang": "zh-cn",
                "createTimeStr": "12-12"
            },
            {
                "summary": "回顾2017，2018年的熊市，历史又是那么惊人的相似。现在提早布局买算力挖矿囤币，坐稳牛市快车，一旦币价起飞，等待你的将是币价和算力价格的双丰收。",
                "isRecommend": 0,
                "createTimeLong": 1576150186000,
                "sourceLink": null,
                "creatorName": "17748488352",
                "source": "BitSG币星",
                "title": "BitSG币星携手HashNest算力巢，让你坐稳牛市快车",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/d3f09b1bc2f5438f8e99b96ce4c49311",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-12 19:29:46",
                "firstBrowse": 0,
                "id": 39644,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 12054,
                "lang": "zh-cn",
                "createTimeStr": "12-12"
            },
            {
                "summary": "12月8日，由中国区块链应用研究中心主办的区块链高峰论坛在北京召开。本届论坛的主题很有意思，叫“即将流行的未来区块链2020展望”，从中可以窥见主办方的美好期待。",
                "isRecommend": 0,
                "createTimeLong": 1576117616000,
                "sourceLink": null,
                "creatorName": "SEO",
                "source": "巴比特",
                "title": "郭宇航：呼吁国家试点比特币挖矿特别税，反哺区块链技术研发",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/c60e9d19af8640a48faef746208fab24",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-12 10:26:56",
                "firstBrowse": 0,
                "id": 39614,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 17097,
                "lang": "zh-cn",
                "createTimeStr": "12-12"
            },
            {
                "summary": "比特大陆本周一上午召开了股东大会，会议上詹克团要求罢免公司全体董事并要求选举其为唯一董事。",
                "isRecommend": 0,
                "createTimeLong": 1575881921000,
                "sourceLink": null,
                "creatorName": "SEO",
                "source": "火星财经",
                "title": "比特大陆召开股东会，詹克团提议罢免全体董事遭股东否决",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/01bc77bacdca4d3ebcc3ea1910fe4bff",
                "articleType": 1,
                "isStick": 0,
                "createTime": "2019-12-09 16:58:41",
                "firstBrowse": 0,
                "id": 39442,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 11496,
                "lang": "zh-cn",
                "createTimeStr": "12-09"
            },
            {
                "summary": "区块律动 BlockBeats 消息，比特大陆本周一上午召开了股东大会，会议上詹克团要求罢免公司全体董事并要求选举其为唯一董事。但该提议遭到了包括众多投资人股东在内的其他股东否决。据参加这场股东会的一位投资人股东表示「不明白为什么詹克团主动要求召开这次股东会，自取其辱」。该投资人还表示「吴忌寒回归后一月，比特大陆蒸蒸日上、士气高涨。」",
                "isRecommend": 0,
                "createTimeLong": 1575877788000,
                "sourceLink": "https://www.theblockbeats.com/news/6346?from=groupmessage&isappinstalled=0",
                "creatorName": "17780452756",
                "source": "区块律动BlockBeats",
                "title": "比特大陆召开股东会，詹克团提议罢免全体董事遭股东否决",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/b0cdaf1773b945ddb06c020707dde685",
                "articleType": 2,
                "isStick": 0,
                "createTime": "2019-12-09 15:49:48",
                "firstBrowse": 0,
                "id": 39438,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 6230,
                "lang": "zh-cn",
                "createTimeStr": "12-09"
            },
            {
                "summary": "12月8日上午，在钛媒体和国家新媒体产业基地联合主办的2019 T-EDGE新金融峰会暨CHAINSIGHTS金融科技与区块链中国峰会上，嘉楠科技联席董事长孔剑平在圆桌论坛环节进行了分享。",
                "isRecommend": 0,
                "createTimeLong": 1575790515000,
                "sourceLink": "https://www.jinse.com/news/blockchain/543941.html",
                "creatorName": "17780452756",
                "source": "每日经济新闻",
                "title": "嘉楠科技联席董事长孔剑平:区块链行业是上升阶段 给我们的试错机会多",
                "categoryName": null,
                "picUrl": "https://pulsar-resource.oss-cn-shanghai.aliyuncs.com/operate/2e5f874471fc422b8faa41ed8b4ab4e1",
                "articleType": 2,
                "isStick": 0,
                "createTime": "2019-12-08 15:35:15",
                "firstBrowse": 0,
                "id": 39391,
                "sourceLogo": "http://static.pulsar.link/operate/63d43039fd3345c592456767785b6a86",
                "browseNum": 14522,
                "lang": "zh-cn",
                "createTimeStr": "12-08"
            }
        ],
        "pageNum": 2,
        "navigatePages": 8,
        "total": 85,
        "navigateFirstPage": 1,
        "pages": 9,
        "size": 10,
        "isLastPage": false,
        "hasPreviousPage": true,
        "navigateLastPage": 8,
        "isFirstPage": false
    },
    "success": true,
    "errorMessage": null,
    "errorCode": "",
    "message": ""
}
  return builder(list)
}

Mock.mock(/\/api\/newsList/,'post',newsList)