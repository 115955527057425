import Mock from 'mockjs'

console.log('mock mounting')

require('./api/news')

require('./api/newsDetail')


Mock.setup({
  timeout:800
})

console.log('mock mounted')