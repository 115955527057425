import React, { Component, Fragment } from 'react'

class Enterprise extends Component {
  render() {
    return (
      <Fragment>
        <section>
          <p className="content-title">
            实验室介绍 / LABORATORY INTRODUCTION
          </p>
          <p>墨子安全实验室是专业的区块链安全实验室，发源于墨客区块链和井通区块链的安全事业部。自2017年成立以来，一直聚焦区块链安全服务领域。
实验室现有信息安全专业博士3名，高级安全工程师7名。</p>
          <p>实验室拥有区块链风险评估平台、智能合约安全审计平台、DApp安全监测平台、数字资产综合防护平台、量子随机数服务系统、私钥部分缺失找回系统、数字资产流转可视化系统等软硬件安全设施，可以提供7大类28种区块链安全服务。</p>
          <p>实验室已经为多家公链、联盟链、钱包、交易所和矿池提供专业安全服务。</p>
        </section>
        <section className="culture">
          <p className="content-title">
            文化与理念 / CULTURE AND IDEA
          </p>
          <div className="idea-item">
            <p>
              <span>定位</span>
              ：专业的区块链安全服务商
            </p>
            <p><span>理念</span>
             ：专注区块链生态安全</p>
          </div>
          <div className="idea-item">
            <p><span>目标</span>
            ：坚持三个面向*，提供优质服务</p>
            <p><span>使命</span>
              ：为区块链产业发展保驾护航</p>
          </div>
          <p>针对区块链产业日益迫切的安全需求，墨子安全实验室基于“能攻善守，攻防一体”的技术定位，坚持“面向技术前沿、面向一线需求、面向行业痛点”的发展方向，提供专业可信的区块链安全服务。</p>
        </section>
        <section>
          <p className="content-title">
            发展历程 / DEVELOPMENT HISTORY
          </p>
          <div className="history-step">
            <div className="step-line"></div>
            <div className='history-item right-item'>
              <div>
                <span className='cirle-decorator' style={{width:'18px',height:'18px',left:'-8px'}}></span>
                <p className='time'>2020</p>
                <p>致力于发展成为国内领先、国际一流的区块链安全服务提供商</p>
              </div>
            </div>
            <div className='history-item left-item'>
              <div>
                <span className='cirle-decorator'></span>
                <p className='time'>2019</p>
                <p>面向实际需求，研发多款区块链安全产品，实现智能安全服务
面向区块链行业，开展独立运营</p>
              </div>
            </div>
            <div className='history-item right-item'>
              <div>
                <span className='cirle-decorator'></span>
                <p className='time'>2018</p>
                <p>组建专业区块链安全团队，成立墨子安全实验室，立足区块链安全领域进行深耕</p>
              </div>
            </div>
            <div className='history-item left-item'>
              <div>
                <span className='cirle-decorator'></span>
                <p className='time'>2017</p>
                <p>开始为区块链机构提供安全咨询和技术服务</p>
              </div>
            </div>
            <div className='history-item right-item'>
              <div>
                <span className='cirle-decorator'></span>
                <p className='time'>2016</p>
                <p>开始关注区块链领域的安全问题，并根据专业兴趣进行专项研究</p>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default Enterprise