import React , { Component , Fragment} from 'react'
// import Banner from '@components/Banner'
import { IndexProduct , IndexBussiness , IndexPartner , IndexService } from '@components/Index'
import Sider from '@components/Layouts/Sider'
import { withRouter } from 'react-router-dom'
import '@styles/index.scss'
import 'animate.css'
class Index extends Component{
  render(){
    return (
      <Fragment>
        <IndexProduct />
        <IndexBussiness />
        <IndexService />
        <IndexPartner />
        <Sider />
      </Fragment>
    )
  }
}

export default withRouter(Index)