import React , { Component } from 'react'
import logo from '@img/ft-logo.png'
import wxcode from '@img/wechat.jpg'

class Footer extends Component{
  render(){
    return (
      <footer id="footer">
        {/* Footer */}
        <section className="ft-desc">
          <div className="container">
           <div className="ft-left">
              <img src={logo} alt="墨子安全实验室" />
              <div>
                <p>
                  <span>网址：www.mozi.com</span>
                  <span>电话：17373734690</span>
                </p>
                <p>
                  <span>传真：010-7836778</span>
                  <span>邮箱：mozi@163.com</span>
                </p>
                <p>地址：北京市朝阳区大屯路甲21号天创世缘</p>
              </div>
           </div>
           <div className="ft-right">
              <img src={wxcode} alt="墨子安全实验室" />
           </div>
          </div>
        </section>
        <section className="ft-info">
          <div className="container">
          ©墨子安全有限公司版权所有 京ICP备11025918号-15 京公网安备11010802011826号     
          总部地址：北京市海淀区知春路7号
          </div> 
        </section>
      </footer>
    )
  }
}

export default Footer