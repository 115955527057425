import React , { Component } from 'react'

// 智能合约检测
const hyjc = ()=>{
  return (
    <div>
      <p>智能合约是区块链产业中非常重要的组成部分，但是由于对智能合约缺乏相应的开发经验和检测工具，因智能合约代码漏洞引起的安全事件层出不穷，给行业带来了很大的经济损失。智能合约一般用来控制数字资产的流传，应用在贸易结算、数字资产交易、票据交易等场景中，其漏洞的严重性远高于普通的软件程序。</p> 
      <p>根据Token Club研究院对近年来区块链安全事件统计数据显示，易受攻击点被攻击次数共计123次，其中智能合约占比21.14%，易受攻击点带来的经济损失共计347984.08万美元，其中智能合约占比41.01%。重大安全事件数量与安全事件造成的经济损失也在2018年到达一个新高点。</p> 
      <p>我们研发的“臭氧层”智能合约安全检测平台，用于解决智能合约的安全检测问题，提前发现合约的安全漏洞，减少合约带病部署后安全事件的爆发，保障区块链应用的良性发展。可针对多种流行的区块链虚拟机、多种智能合约编程语言，对智能合约进行基于源代码和可执行代码的安全检测。</p>
      <p>目前很多行业开始通过区块链构建业务体系，不可避免的会涉及到智能合约的开发、部署和使用，本产品可以用于所有使用或希望在区块链上通过智能合约构建业务的部门、机构、个人，为客户的智能合约提供安全检测服务，减少因合约漏洞引起的安全事件发生，保障客户的资产、声誉安全。</p>
    </div>
  )
}
// 数字资产追踪
const zczz = ()=>{
  return (
   <div> 
    <p>数字资产安全是区块链安全的核心，数字资产流转情况是区块链用户最关注的内容之一。
目前，区块链产业还处于早期阶段，区块链浏览器是用户查看数字资产流转的最常用工具，区块链浏览器主要作用是将原始的账本数据以最朴素的方式展示出来，虽然全面，但并不适合普通用户查看，同时也容易将一些异常点隐藏在成堆的原始数据中。</p>
    <p>我们研发的“平流层”数字资产追踪平台，以可视化的方式向用户展示资产流转情况，不但可以使普通用户更容易查看资金流传信息，了解每笔资金的来龙去脉；而且使得机构用户更方便发现异常行为，提前发现安全事件的端倪。</p>
   
   </div> 
  )
}

// 私钥安全托管平台
const qkljc = ()=>{
  return (
   <div> 
    <p>私钥是证明用户对数字资产所有权的唯一凭证，至关重要。如果私钥一旦丢失，几乎无法找回，所有关联资产将全部丢失。因此，如何将私钥进行安全托管，在用户私钥丢失之后可以将资产保全？这是区块链行业一直困扰的问题，甚至影响到了区块链行业的发展。</p>
    <p>由于私钥和资产是直接关联的，托管私钥的同时相当于把资产也托管出去了，这会直接影响到用户的资产安全，因此，在私钥托管领域面临一个两难问题：如何只托管私钥，但不托管资产？</p>
    <p>我们采用多方安全计算技术解决了这一问题，提出“平流层”私钥安全托管平台。该平台可以让用户放心地托管私钥，但不必担心资产安全。当用户私钥丢失后，该平台可以及时保全用户资产。</p>
    <p>技术方案已申请发明专利。</p>
   </div> 
  )
}

// 私钥丢失找回
const dszh = ()=>{
  return (
   <div> 
    <p>鉴于离线保存是最安全的保存方式，因此用户经常被建议使用纸笔方式记录自己的私钥。然而，我们在实践中发现，很多用户遇到私钥丢失的问题，特别是一些使用纸笔方式记录私钥的用户，容易出现笔迹模糊、纸张污损、记录出错等问题。此时，如果想找回私钥将面临很大的挑战。</p>
    <p>我们研发的“电离层”私钥缺失找回平台，采用分布式密码破解技术，为您定制最合理最高效的私钥找回方案，可以很好地解决用户私钥部分缺失的问题，帮您重新找回丢失的数字资产。</p>
    
   </div> 
  )
}

// 反洗钱去匿名化
const nmh = ()=>{
  return (
   <div> 
    <p>目前，区块链行业依然处于早期的野蛮生长阶段，各种安全事件频发，特别涉及大量数字资产的被盗、欺诈、洗钱等非法流转行为。由于区块链具有一定的匿名特性，被广泛作为非法资金流转的工具，包括恐怖主义、赃款转移、隐蔽支付等。这在很大程度上败坏了区块链的声誉，助长了犯罪分子的气焰，影响区块链产业的健康发展。</p>
    <p>我们研发的“电离层”反洗钱去匿名化平台，结合大数据分析和可视化技术，对区块链行业中的非法资金流转情况进行密切监控，并进行匿名化处理，协助相关部门实现对违法犯罪行的有效打击。</p>
   </div> 
  )
}


class ProductPage extends Component{
  constructor(props){
    super(props)
    this.state = {
      product:[
        {
          title:'智能合约检测平台',
          sub:'OZONOSPHERE',
          content:hyjc
        },
        {
          title:'数字资产追踪平台',
          sub:'STRATOSPHERE',
          content:zczz
        },
        {
          title:'私钥安全托管平台',
          sub:'STRATOSPHERE',
          content:qkljc
        },
        {
          title:'私钥缺失找回平台',
          sub:'IONOSPHERE',
          content:dszh
        },
        {
          title:'反洗钱去匿名化平台',
          sub:'IONOSPHERE',
          content:nmh
        }
      ],
      activeIndex:0
    }
  }
  render(){
    let { state } = this
    return (
      <div id="product-page" className='other-page'>
        <div className='container'>
          <div className="product-page-box">
            <div className="box-nav">
              <div className="nav-title">
                <p>产品展示</p>
                <p className="sub-title">
                  <span></span>
                  PRODUCT DISPLAY
                  <span></span>
                </p>
              </div>
              <ul className='nav-wrapper'>
                {
                  state.product.map((item,index)=>{
                    return (
                      <li 
                        key = {index}
                        className = {index===state.activeIndex?'active':''}
                        onClick = {this.setActive.bind(this,index)}
                      >
                        {item.title}
                      </li>
                    )
                  })
                }
                {/* <li className="active">智能合约检测平台</li>
                <li>数字资产追踪平台</li>
                <li>区块链监测平台</li>
                <li>私钥缺失找回平台</li>
                <li>反洗钱去匿名化平台</li> */}
              </ul>
            </div>
            <div className="box-content">
              <div className="content-title">
                <p>{state.product[state.activeIndex].title}</p>
                <p>{state.product[state.activeIndex].sub}</p>
              </div>
              <div className="content-wrapper">
                {state.product[state.activeIndex].content()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  setActive(index){
    this.setState({
      activeIndex:index
    })
  }
}

export default ProductPage