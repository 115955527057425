import React , { Component } from 'react'
import { withRouter } from 'react-router-dom'
import AboutPage from '@components/About'
import '@styles/about.scss'

class About extends Component{
  render(){
    return (
      <div>
        <AboutPage />
      </div>
    )
  }
}

export default withRouter(About)