import React , { Component } from 'react'
import { Link , withRouter } from 'react-router-dom'
import routers from '@router/config'

class Nav extends Component{
  constructor(props){
    super(props)
    this.state = {
      initLeft:0,
      left:0
    }
  }
  render(){
    let { state } = this 
    
    return (
      <div className="hd-nav_box">
        <ul className="nav">
          {
            routers.map((item,index)=>{
              return item.nav?(
                <li key={index} 
                  onMouseEnter={this.mouseWithMove.bind(this,index)} 
                  onMouseLeave={this.mouseWithBack}
                  onClick={this.changeInitLeft.bind(this,index)}
                >
                  <Link to={item.path} >
                    {item.title}
                  </Link>
                </li>)
                :''
            })
          }
        </ul>
        <span className="mousewith" style={{left:state.left}}></span>
      </div> 
    )
  }
  mouseWithMove = (index)=>{
    console.log(index)
    this.setState({
      left:index*100
    })
  }
  mouseWithBack = ()=>{
    this.setState({
      left:this.state.initLeft
    })
  }
  changeInitLeft = (index)=>{
    this.setState({
      initLeft:index*100,
      left:index*100
    })
  }
  componentDidMount(){
    // console.log('DidMount')
    let { props } = this
    let path = props.location.pathname
    var index = routers.findIndex(item=>item.path===path)
    if(index===-1&&path.includes('dynamic')){
      index = 3
    }
    this.setState({
      left:index*100,
      initLeft:index*100
    })
  }
  componentWillReceiveProps(nextProps){
    // console.log('WillReceiveProps')
    let path = nextProps.location.pathname
    var index = routers.findIndex(item=>item.path===path)
    if(index===-1&&path.includes('dynamic')){
      index = 3
    }
    this.setState({
      left:index*100,
      initLeft:index*100
    })
  }
}

export default withRouter(Nav)