import React , { Component } from 'react'
import { Logo , Nav , Switcher } from '@components/Header'

class Header extends Component{
  render(){
    return (
      <header id="header">
        <div className="container">
          <Logo />
          <Nav />
          {/* <Switcher /> */}
        </div>
      </header>
    )
  }
}

export default Header