import React , { Component } from 'react'
import { partner1 , partner2 , partner3 , partner4 , partner5 , partner6 , partner7  } from '@img/partner'

class IndexPartner extends Component{
  render(){
    return (
      <section id="index-partner">
        <div className="container">
          <ul>
            <li style={{backgroundImage:`url(${partner1})`}}></li>
            <li style={{backgroundImage:`url(${partner2})`}}></li>
            <li style={{backgroundImage:`url(${partner3})`}}></li>
            <li style={{backgroundImage:`url(${partner4})`}}></li>
          </ul>
          <ul>
            <li style={{backgroundImage:`url(${partner5})`}}></li>
            <li style={{backgroundImage:`url(${partner6})`}}></li>
            <li style={{backgroundImage:`url(${partner7})`}}></li>
            {/* <li style={{backgroundImage:`url(${partner8})`}}></li> */}
          </ul>
        
        </div>
      </section>
    )
  }
}

export default IndexPartner