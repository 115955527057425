import React , { Component } from 'react'
import { Route } from 'react-router-dom'
import NProgress from 'nprogress'

class ContentRouter extends Component{
  render(){
    let rests = this.props
    // console.log(rests)
    let ContentComponents = rests.component
    return (
      <Route rests render={props=>{
        document.title = `${rests.title}——墨子安全实验室`
        return <ContentComponents {...props} />
      }
      } />
    )
  }
  UNSAFE_componentWillMount(){
    NProgress.start()
  }
  componentDidMount(){
    NProgress.done()
  }
}


export default ContentRouter