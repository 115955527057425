import React , { useState } from 'react'
import Swiper from 'react-id-swiper'
import Extra1 from '@img/extra1.png'
import Extra2 from '@img/extra2.png'
import Extra3 from '@img/extra3.png'


const  ServicePage =  (props)=>{
  const [swiper, updateSwiper] = useState(null);
  const goNext = () => {
    if (swiper !== null) {
      console.log(swiper.activeIndex)
      swiper.slideNext();
    }
  }
  const goPrev = () => {
    if (swiper !== null) {
      console.log(swiper.activeIndex)
      swiper.slidePrev();
    }
  }
  const params = {
    slidesPerView: 3,
    spaceBetween: 30
    // loop:true
  }

  return (
    <div id="service-page" className='other-page'>
      <div className="container">
        <div className="service-box">
          <section>
            <div className="service-title">
              <p>主要服务</p>
              <div className="sub-title">
                <p className="line"></p>
                <p>MAIN SERVICE</p>
                <p className="line"></p>
              </div>
            </div>
            <div className="service-swiper">
              <div className="service-prev service-slide" onClick={goPrev}></div>
              <div className="service-next service-slide" onClick={goNext}></div>
              <Swiper {...params} getSwiper={updateSwiper} >
                {
                  props.service.map((item,index)=>{
                    return (
                      <div 
                        className = {index===props.activeIndex?'service-item active':'service-item'}
                        key={index} 
                        onClick={props.setActiveIndex.bind(this,index)}
                      >
                        <div className="item-img-box">
                          <img src={item.img} alt={item.title} /> 
                        </div>
                        <p>{item.title}</p>
                        <p>{item.sub}</p>
                      </div>
                    )
                  })
                }
              </Swiper>
            </div>
            <div className="service-content">
              <div className="content-title">
                <p>关于{props.service[props.activeIndex].title}</p>
                <p>ABOUT {props.service[props.activeIndex].sub}</p>
              </div>
              <div>
                {props.service[props.activeIndex].content}
              </div>
            </div>
          </section>
          <section>
            <div className="service-title">
              <p>附加服务</p>
              <div className="sub-title">
                <p className="line"></p>
                <p>MAIN SERVICE</p>
                <p className="line"></p>
              </div>
              <div className="extra-box">
                <div className="extra-item">
                  <img src={Extra1} alt="真随机数服务平台" /> 
                  <p className="extra-title">真随机数服务平台</p>
                  <p className="extra-content">
                    随机数是信息安全的基石，同时也是区块链安全的薄弱环节。
                  </p>
                  <p className="extra-content">
                    我们基于量子随机数发生器提供安全可信的真随机数服务，为区块链安全提供安全基础设施。
                  </p>
                </div>
                <div className="extra-item">
                  <img src={Extra2} alt="用户隐私保护系统" /> 
                  <p className="extra-title">用户隐私保护系统</p>
                  <p className="extra-content">        
                    公开透明是区块链技术的优点之一，但是也有利于不法之徒对用户资产的非法窥探和跟踪。        
                  </p>
                  <p className="extra-content">
                    我们为用户提供隐私保护服务，有效抵制对用户的非法窥探。
                  </p>
                </div>
                <div className="extra-item">
                  <img src={Extra3} alt="区块链安全培训" /> 
                  <p className="extra-title">区块链安全培训</p>
                  <p className="extra-content">
                    区块链时代，信息安全知识成为人们的标配。为了有效保障个人数字资产的安全，必须具备必要的安全意识；为了开发实用的区块链应用，必须具备基本的信息安全知识。
这一切，我们都已经为您准备妥当！
                  </p>
                  {/* <p className="extra-content">
                    我们基于量子随机数发生器提供安全可信的真随机数服务，为区块链安全提供安全基础设施。
                  </p> */}
                </div>
              </div>
            </div>
            
          </section>
        </div>
      </div>
    </div>
  )
  
}



export default ServicePage