import React , { Component , Fragment } from 'react'
import code from '@img/about-qrcode.png'
import map from '@img/about-map.png'

class Connect extends Component{
  render(){
    return (
      <Fragment>
        <div className="connect-title">
          <div className='title-line'></div>
          <p>CON</p>
          <div>
            <p>联系我们</p>
            <p>TACT US</p>
          </div>
        </div>
        <div className="connect-one">
          <div className="connect-info">
            <div>
              <p>网址：www.mozi.com</p>
              <p>电话：13910989401</p>
            </div>
            <div>
              <p>QQ：12555615</p>
              <p>邮箱：mozi.163.com </p>
            </div>
             <p>地址：北京市朝阳区大屯路甲21号天创世缘</p>
             <div>
               <img className="about-qrcode" src={code} alt="墨子安全实验室" />
             </div>
          </div>  
          <div className="connect-map">
            <img src={map}  alt="地图" />
          </div>

        </div>
        <div className="connect-two">
          <p className="part-two-title">
            您也可以这样联系我们
          </p>
          <div>
            <form>
              <div className="form-item">
                <label htmlFor="name">姓名</label>
                <input type="text" name="name" id="name" placeholder="王小明" required />
              </div>
              <div className="form-item">
                <label htmlFor="tel">电话</label>
                <input type="text" name="tel" id="tel" placeholder="1772778" required />
              </div>
              <div className="form-item">
                <label htmlFor="email">邮箱</label>
                <input type="text" name="email" id="email" placeholder="xxxx@163.com" required />
              </div>
              <div className="form-item message-board">
                <label htmlFor="message">留言板</label>
                {/* <input type="text" name="name" placeholder="王小明" /> */}
                <textarea name="message" id="message" placeholder="王小明" required />
              </div>
              <p className="tips">我们收到会尽快联系您</p>
              <div className="form-item">
                <input className="form-submit-btn" type="submit" value="提交留言" />
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Connect