import React , { Component } from 'react'
import Connet from './Connect'
import Team from './Team'
import Enterprise from './Enterprise'


class AboutPage extends Component{
  constructor(props){
    super(props)
    this.state = {
      data:[
        {
          title:'企业介绍',
          content:Enterprise
        },
        {
          title:'团队介绍',
          content:Team
        },
        {
          title:'联系我们',
          content:Connet
        }
      ],
      activeIndex:0
    }
  }
  render(){
    let { state } = this 
    let ContentComponent =  state.data[state.activeIndex].content 
    return (
      <div id="about-page" className='other-page'>
        <div className='container'>
          <div className="about-box"> 
            <div className="about-nav">
              <ul>
                {
                  state.data.map((item,index)=>{
                    return (
                      <li 
                      key = {index}
                      className = {index===state.activeIndex?'active':''}
                      onClick = {this.setActive.bind(this,index)}
                      >
                        {item.title}
                      </li>
                    )
                  })
                }
              </ul>
              <div 
                className="active-decorator" 
                style={{top:`${40+state.activeIndex*50}px`}}
              ></div>
            </div>
            <div className="about-content">
              <ContentComponent />
            </div>
          </div>
        </div>
      </div>
    )
  }
  setActive = (index)=>{
    this.setState({
      activeIndex:index
    })
  }
}

export default AboutPage