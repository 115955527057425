import React , { Component } from 'react'
import img from '@img/news-img.png'
import { Link } from 'react-router-dom'
import { getNewsList }  from '@request/api'

class DynamicPage extends Component{
  constructor(props){
    super(props)
    this.state = {
      // list:[1,2,3,4,5,6,7,8],
      data:{
        navigatepageNums:[1,2,3],
        list:[1,2,3,4,5,6,7,8],
      },
      nav:{
        data:[{
          title:'公司要闻',
          categoryId:37
        },{
          title:'技术成果',
          categoryId:40
        },{
          title:'行业资讯',
          categoryId:42
        }]
      },
      categoryId:37,
      pageNumber:1
    }
  }
  render(){
    let { state } = this
    let { nav } = state
    let { navigatepageNums } = state.data
    // .navigatepageNums
    return (
      <div id="dynamic-page" className='other-page'>
        <div className='container'>
          <div className="dynamic-box">
            <ul className="dynamic-nav">
              {
                nav.data.map((item,index)=>{
                  return (
                  <li 
                    key={item.categoryId}
                    className={ item.categoryId===state.categoryId?'active':null }
                    onClick={this.setNavActive.bind(this,item.categoryId)}
                  >
                    {item.title}
                  </li>
                  )
                })
              }
            </ul>
            <div className="dynamic-content">
              {
                state.data.list.map((item,index)=>{
                  return (
                    <Link to={`/dynamic/${item.id}`} key={index} title={item.title} alt={item.title}>
                      <div className="dynamic-item" >
                      <div className="item-img">
                        <span className="img-border"></span>
                        <img src={item.picUrl} alt={item.title} /> 
                      </div>
                      <div className="item-detail">
                        <p className="item-title">{item.title}</p>
                        <p className="item-abstract">{item.summary}...</p>
                        <p className="item-sub">
                          <span>发布时间：{item.createTime} 
                          &nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp; 
                          作者：{item.source} </span>
                          <span>详细内容>></span>
                        </p>
                      </div>
                    </div>
                    </Link>
                  ) 
                })
              }
              <div className="pagination-box">
                <ul className="pagination-wrapper">
                  {state.pageNumber===1?
                  <li className="page-prev disabled">上一页</li>
                  :<li className="page-prev" onClick={this.toPrev}>上一页</li> }
                  {
                    navigatepageNums.map((item,index)=>{
                      return (
                      <li 
                        key = {index}
                        className = {item===state.pageNumber?'active':''}
                        onClick = {this.pageChange.bind(this,item) }
                      >
                        {item}
                      </li>
                      )
                    })
                  }
                  {state.pageNumber===navigatepageNums[navigatepageNums.length-1]?
                  <li className="page-next disabled">下一页</li>
                  :<li className="page-next" onClick={this.toNext}>下一页</li> }
                  {/* <li className={`${state.pageNumber===navigatepageNums[navigatepageNums.length-1]?'disabled':''} page-next`}>下一页</li> */}
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
  }
  setNavActive = (index)=>{
    let { state } = this
    state.categoryId = index
    state.pageNumber = 1
    this.setState(state)
    this.getList()
  }
  toPrev = ()=>{
    let { state } = this
    let page = state.pageNumber-1
    this.pageChange(page)
  }
  toNext = ()=>{
    let { state } = this
    let page = state.pageNumber+1
    this.pageChange(page)
  }
  pageChange(page){
    let { state } = this
    state.pageNumber = page
    this.setState(state)
    this.getList()
  }
  getList(){
    let { state } = this
    getNewsList({
      categoryId:state.categoryId,
      pageNumber:state.pageNumber
    }).then(res=>{
      state.data = res.data
      this.setState(state)
    })
  }
  componentDidMount(){
    this.getList()
  }
}

export default DynamicPage