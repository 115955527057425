import React , { Component } from 'react'

class LanguageSwitcher extends Component{
  render(){
    return (
      <div className="lang_switcher">
        <span className="lang_icon"></span>
        中文
      </div>
    )
  }
}

export default LanguageSwitcher